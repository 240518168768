<template>
  <div class="py-lg-4">
    <div class="container">
      <div class="row">
        <div class="mb-lg-4 col-xl-3 col-sm-6 mb-xl-0">
          <mini-statistics-card
            title="Bookings"
            :value="this.uRole == 1 ? this.totalBooking : this.userTotalBooking"
            :icon="{
              component: 'ni ni-paper-diploma',
              background: 'bg-gradient-dark',
            }"
          />
        </div>

        <div v-if="this.uRole == 1" class="mb-lg-4 col-xl-3 col-sm-6 mb-xl-0">
          <mini-statistics-card
            title="Services"
            :value="this.serviceCount"
            :icon="{
              component: 'ni ni-controller',
              background: 'bg-gradient-dark',
            }"
          />
        </div>

        <div class="mb-lg-4 col-xl-3 col-sm-6 mb-xl-0">
          <mini-statistics-card
            title="Reviews"
            :value="this.ratingCount"
            :icon="{
              component: 'ni ni-satisfied',
              background: 'bg-gradient-dark',
            }"
          />
        </div>
        <div class="col-xl-3 col-sm-6">
          <mini-statistics-card
            title="Notifications"
            :value="this.notificationCount"
            :icon="{
              component: 'ni ni-bell-55',
              background: 'bg-gradient-dark',
            }"
          />
        </div>
      </div>

      <div class="row pt-5 pt-md-0">
        <div class="col-12 col-lg-5 mb-3 mb-lg-0">
          <div class="card border-gray-300 p-2">
            <div
              class="card-header bg-white border-0 text-center d-flex flex-row flex-column align-items-center justify-content-center px-1 px-lg-4"
            >
              <div class="profile-thumbnail dashboard-avatar mx-lg-auto me-3">
                <img
                  v-if="user.profile_img != ''"
                  :src="imgUrl + user.profile_img"
                  class="card-img-top rounded-circle border-white"
                  :alt="user.name"
                />
                <img
                  v-else
                  :src="imgUrl + 'uploads/profile_img/labes-avatar-profile.jpg'"
                  class="card-img-top rounded-circle border-white"
                  :alt="user.name"
                />
              </div>
              <span class="h6 my-0 my-lg-1 me-0">{{ user.name }}</span>
              <span class="my-0 my-lg-1 me-0 text-xs">{{ user.token }}</span>
              <router-link
                :to="{ name: 'Settings' }"
                class="px-0 nav-link font-weight-bold"
                :class="isNavFixed && !darkMode ? ' opacity-8 text-dark' : ' '"
              >
                <span class="me-2 btn btn-gray-300 btn-xs"
                  ><span class="d-none fa fa-user mx-3"></span>
                  Profile
                </span>
              </router-link>
            </div>
            <div class=" ">
              <div v-if="this.uRole == 1" class="mt-sm-0">
                <div class="my-2">
                  <div class="card bg-white">
                    <div class="card-body">
                      <h6 class="mb-0 text-lg text-gray">
                        Wallet ({{ walletHistory.wallet_info.wallet.token }} )
                      </h6>

                      <div class="mb-2">
                        <h6 class="mb-0 text-xs text-gray">Balance</h6>
                        <sup class="text-gray"></sup>
                        <span class="h2 text-gray"
                          >{{
                            (
                              walletHistory.wallet_info.wallet.wallet_amt / 1000
                            ).toFixed(3)
                          }}
                          /
                          {{ walletHistory.wallet_info.wallet.currency }}
                        </span>
                        <div class="row">
                          <div class="col">
                            <small class="text-gray text-xxs opacity-8">
                              Total Debit :
                              {{
                                (
                                  walletHistory.wallet_info.wallet.total_debit /
                                  1000
                                ).toFixed(3)
                              }}
                              /
                              {{
                                walletHistory.wallet_info.wallet.currency
                              }}</small
                            >
                            <div class="progress progress-xs my-2">
                              <div
                                class="progress-bar bg-success"
                                style="width: 50%"
                              ></div>
                            </div>
                          </div>
                          <div class="col">
                            <small class="text-gray text-xxs opacity-8"
                              >Total Crédit :
                              {{
                                (
                                  walletHistory.wallet_info.wallet
                                    .total_credit / 1000
                                ).toFixed(3)
                              }}
                              /
                              {{
                                walletHistory.wallet_info.wallet.currency
                              }}</small
                            >
                            <div class="progress progress-xs my-2">
                              <div
                                class="progress-bar bg-warning"
                                style="width: 50%"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div class="text-gray text-xxs opacity-8 mt-2 text-sm">
                          Total Revenu
                        </div>
                        <div>
                          <span class="text-gray text-xxs opacity-8">
                            {{
                              (
                                walletHistory.wallet_info.wallet.total_credit /
                                1000
                              ).toFixed(3)
                            }}
                            /
                            {{
                              walletHistory.wallet_info.wallet.currency
                            }}</span
                          >
                        </div>
                        <div class="text-gray text-xxs opacity-8 mt-2 text-sm">
                          Frais d'internet
                        </div>
                        <div>
                          <span class="text-danger text-xxs font-weight-600">
                            0,000 DT
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.uRole == 1">
                <subscription-card />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!this.contentLoaded">LOADER</div>
        <div v-else class="col-12 col-lg-7 mb-3 mb-lg-0">
          <div class="card bg-white border-gray-300 mb-4 mb-lg-5">
            <div class="">
              <div class="row justify-content-center">
                <div class="col-12" v-if="this.uRole == 1">
                  <div
                    v-for="servicePro in serviceProvider.services"
                    :key="servicePro.id"
                    class="card border-gray-300 mb-4"
                  >
                    <div class="row g-0 align-items-center">
                      <div class="col-12 col-lg-6 col-xl-4">
                        <a href="#"
                          ><img
                            :src="imgUrl + servicePro.service_image"
                            alt=""
                            class="card-img p-2 rounded-xl"
                        /></a>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-8">
                        <div class="card-body py-lg-0">
                          <div class="d-flex g-0 align-items-center mb-2">
                            <div class="col text-left">
                              <ul class="list-group mb-0">
                                <li class="list-group-item border-0 small p-0">
                                  <span
                                    class="fas fa-medal text-tertiary me-2"
                                  ></span
                                  >{{ servicePro.category_name }}
                                </li>
                              </ul>
                            </div>
                            <div class="col-lg-1 col-2 my-auto pe-0">
                              <router-link
                                :to="{ name: 'Edit Service' }"
                                class="border-radius-md"
                              >
                                <button
                                  class="btn btn-icon-only shadow-none text-dark mb-0 me-3 me-sm-0"
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="Video call"
                                >
                                  <i class="ni ni-settings"></i>
                                </button>
                              </router-link>
                            </div>
                          </div>
                          <a href="#"
                            ><h2 class="h5">
                              {{ servicePro.service_title }}
                            </h2></a
                          >
                          <div class="col d-flex ps-0">
                            <span class="text-success font-small me-3"
                              ><span class="fas fa-check-circle me-2"></span
                              >Active</span
                            >
                            <span class="d-none text-danger font-small me-3"
                              ><span class="fas fa-times-circle me-2"></span
                              >Disabled</span
                            >

                            <span class="text-muted font-small me-3"
                              ><span class="fas fa-star me-2"></span>4.5</span
                            >
                            <span class="text-muted font-small me-3"
                              ><span class="me-2"
                                >({{ servicePro.rating_count }})</span
                              >Avis</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="this.showBtnAddService"
                    class="card border-gray-300 mb-4"
                  >
                    <div class="row g-0 align-items-center">
                      <div class="col-12">
                        <div class="d-grid">
                          <a
                            href="./edit-item.html"
                            class="btn btn-outline-info m-4 py-3"
                            ><span class="me-2"
                              ><span class="fas fa-plus"></span></span
                            >Service
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-show="this.uRole == 1 && this.diplayCalendar == true"
                    class="card border-gray-300 mb-4"
                  >
                    <div class="row g-0 align-items-center">
                      <div class="col-12 col-lg-12 col-xl-12">
                        <div class="card-body py-lg-2">
                          <h6 class="mb-2 text-lg text-gray d-none">
                            Recent Bookings
                          </h6>

                          <div class="mb-2" id="fcalendar">
                            <FullCalendar :options="calendarOptions" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="this.uRole == 2">
                  <div class="border-gray-300 mb-4 p-4">
                    <div class="text-xxs bg-white">
                      <div class="">
                        <h6 class="mb-0 text-lg text-gray">Recent Booking</h6>

                        <div class="table-responsive">
                          <table id="order-list" class="table table-flush">
                            <thead class="thead-light">
                              <tr>
                                <th>Id</th>
                                <th>Service</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="booking in this.bookingListUser"
                                :key="booking.id"
                              >
                                <td>
                                  <div class="d-flex align-items-center">
                                    <argon-checkbox class="d-none" />
                                    <p
                                      class="text-xxs font-weight-bold ms-2 mb-0"
                                    >
                                      #L-{{ booking.id }}
                                    </p>
                                  </div>
                                </td>

                                <td class="text-xs">
                                  <div
                                    class="d-flex align-items-center font-weight-bold"
                                  >
                                    <img
                                      :src="imgUrl + booking.profile_img"
                                      class="avatar avatar-xs me-2"
                                      alt="user image"
                                    />
                                    <span>
                                      <router-link
                                        :to="{
                                          name: 'ServiceDetail',
                                          params: { id: booking.service_id },
                                        }"
                                      >
                                        {{ booking.name }}
                                      </router-link>
                                    </span>
                                  </div>
                                  <span class="text-xxs text-center my-2">{{
                                    booking.service_title
                                  }}</span>
                                </td>
                                <td class="font-weight-bold">
                                  <span class="my-2 text-xxs">
                                    {{ booking.service_date }}</span
                                  >
                                </td>
                                <td class="font-weight-bold">
                                  <span class="my-2 text-xxs">
                                    {{ booking.from_time }} -
                                    {{ booking.to_time }}
                                  </span>
                                </td>

                                <td class="text-xxs font-weight-bold">
                                  <div class="d-flex align-items-center">
                                    <span
                                      class="badge badge-sm"
                                      :class="
                                        booking.status == 1
                                          ? 'badge-warning'
                                          : booking.status == 2
                                          ? 'badge-info'
                                          : booking.status == 3
                                          ? 'badge-success'
                                          : booking.status == 4
                                          ? 'badge-warning'
                                          : booking.status == 5
                                          ? 'badge-danger'
                                          : booking.status == 6
                                          ? 'badge-warning'
                                          : booking.status == 7
                                          ? 'badge-danger'
                                          : booking.status == 8
                                          ? 'badge-success'
                                          : ''
                                      "
                                    >
                                      <small v-if="booking.status == 1"
                                        >Pending</small
                                      >
                                      <small v-if="booking.status == 2"
                                        >In progress</small
                                      >
                                      <small v-if="booking.status == 3"
                                        >Complete Request Sent by
                                        Provider</small
                                      >
                                      <small v-if="booking.status == 4"
                                        >Complete Request Sent by
                                        Provider</small
                                      >
                                      <small v-if="booking.status == 5"
                                        >Rejected by Pro</small
                                      >
                                      <small v-if="booking.status == 6"
                                        >service Complete - Payment
                                        Pending</small
                                      >
                                      <small v-if="booking.status == 7"
                                        >Cancelled by Patient</small
                                      >
                                      <small v-if="booking.status == 8"
                                        >Complete</small
                                      >
                                    </span>
                                  </div>
                                </td>

                                <td class="text-xxs font-weight-bold">
                                  <div class="d-flex align-items-center">
                                    <router-link
                                      :to="{
                                        name: 'Booking Details',
                                        params: { id: booking.id },
                                      }"
                                    >
                                      <argon-button
                                        color="success"
                                        variant="outline"
                                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                                      >
                                        <i
                                          class="fas fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </argon-button>
                                    </router-link>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ChannelsChartCard from "@/views/ecommerce/overview/components/ChannelsChartCard.vue";
//import RevenueChartCard from "@/views/ecommerce/overview/components/RevenueChartCard.vue";
//import SalesChartCard from "@/views/ecommerce/overview/components/SalesChartCard.vue";
//import OrdersListCard from "@/examples/Cards/OrdersListCard.vue";
//import DefaultStatisticsCard from "@/views/ecommerce/overview/components/DefaultStatisticsCard.vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createEventId } from "./CalendarUtils/event-utils";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

import SubscriptionCard from "../dashboards/components/SubscriptionCard.vue";
import router from "../../router";

// images
//import US from "@/assets/img/icons/flags/US.png";
//import DE from "@/assets/img/icons/flags/DE.png";
//import GB from "@/assets/img/icons/flags/GB.png";
//import BR from "@/assets/img/icons/flags/BR.png";
//import AU from "@/assets/img/icons/flags/AU.png";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "Overview",
  components: {
    //ChannelsChartCard,
    //RevenueChartCard,
    //SalesChartCard,
    //OrdersListCard,
    MiniStatisticsCard,

    SubscriptionCard,
    FullCalendar,
    //DefaultStatisticsCard,
  },
  data() {
    return {
      //bookings: [],
      bookList: null,
      ratingCount: "",
      notificationCount: "",
      bookingStatusColor: "",
      bookingStatusTitle: "",
      imgUrl: "https://back.labes.com.tn/",
      uRole: "",
      totalBooking: "",
      userTotalBooking: "",
      serviceCount: "",
      showBtnAddService: false,
      diplayCalendar: false,
      contentLoaded: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "today",
        },

        /*events: [
          { title: "08:05:22", date: "2023-05-08", backgroundColor: "#307007" },
          { title: "16:58:10", date: "2023-05-08", backgroundColor: "#950207" },
          { title: "17:40:10", date: "2023-05-08", backgroundColor: "#307007" },
          { title: "18:40:10", date: "2023-05-08", backgroundColor: "#950207" },
          { title: "20:40:10", date: "2023-05-08", backgroundColor: "#950207" },
          { title: "13:58:10", date: "2023-05-08", backgroundColor: "#307007" },
        ],*/
        initialView: "dayGridMonth",
        events: [], // alternatively, use the `events` setting to fetch from a feed
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        //select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        bookingListUser: [],
        // eventsSet: this.handleEvents,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      bookingStatusCode: "",

      currentEvents: [],
    };
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  },
  async created() {
    this.uRole = this.$store.state.auth.user.type;

    if (this.uRole == 1) {
      await this.$store.dispatch("loadBookingList");
      await this.$store.dispatch("loadServiceProvider");
      await this.$store.dispatch("loadWalletHistory");

      if (this.$store.state.bookingList.length != 0) {
        this.createBookingListCalendar();
      } else {
        this.totalBooking = "0";
        this.contentLoaded = true;
        this.diplayCalendar = true;
      }
      if (this.$store.state.serviceProvider.length != 0) {
        this.serviceCount = "1";
      } else {
        this.serviceCount = "0";
        this.showBtnAddService = true;
      }
      if (this.$store.state.bookingList.length != 0) {
        this.ratingCount =
          this.$store.state.bookingList.booking_list[0].rating_count;
      } else {
        this.ratingCount = "0";
      }
    } else {
      await this.$store.dispatch("loadBookingUserList");

      this.bookingListUser = this.$store.state.bookingListUser.booking_list;

      if (this.$store.state.bookingListUser.length != 0) {
        //this.setBookingStatus(this.bookingListUser);
        this.userTotalBooking =
          this.$store.state.bookingListUser.booking_list.length;
      } else {
        this.userTotalBooking = "0";
      }
      this.contentLoaded = true;
      if (this.$store.state.bookingListUser.length != 0) {
        this.ratingCount =
          this.$store.state.bookingListUser.booking_list[0].rating_count;
      } else {
        this.ratingCount = "0";
      }
    }
    if (this.$store.state.notifications.notification_list.length != 0) {
      this.notificationCount =
        this.$store.state.notifications.notification_list.length;
    } else {
      this.notificationCount = "0";
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    subscriptionDetails() {
      return this.$store.state.auth.subscriptionDetails;
    },
    notifications() {
      return this.$store.state.notifications.notification_list;
    },

    /*bookinglist() {
      return this.$store.state.bookingList;
    },
    bookingListUser() {
      return this.$store.state.bookingListUser;
    },*/
    serviceProvider() {
      return this.$store.state.serviceProvider;
    },
    walletHistory() {
      return this.$store.state.walletHistory;
    },
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      let title = prompt("Please enter a new title for your event");
      let calendarApi = selectInfo.view.calendar;

      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    handleEventClick(clickInfo) {
      router.push({
        name: "Booking Details",
        params: { id: `${clickInfo.event.id}` },
      });
      console.log(`${clickInfo.event.id}`);
    },
    handleEvents(events) {
      this.currentEvents = events;
    },

    setBookingStatus(statusCode) {
      //bookings.forEach((booking) => {
      this.bookingStatusCode = statusCode;

      if (this.bookingStatusCode == 1) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Pending";
      } else if (this.bookingStatusCode == 2) {
        this.bookingStatusColor = "badge-info";
        this.bookingStatusTitle = "In progress";
      } else if (this.bookingStatusCode == 3) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 4) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 5) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Rejected by Pro";
      } else if (this.bookingStatusCode == 6) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "service Complete - Payment Pending";
      } else if (this.bookingStatusCode == 7) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Cancelled by Patient";
      } else if (this.bookingStatusCode == 8) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete";
      }
      console.log(
        this.bookingStatusCode +
          ":" +
          this.bookingStatusTitle +
          ":" +
          this.bookingStatusColor
      );
      //});
      return this.bookingStatusTitle;
    },

    createBookingListCalendar() {
      // let tmpBookList = [];

      const books = Array.from(this.$store.state.bookingList.booking_list);
      books.forEach((book) => {
        let bgColor = "";
        // Set BgColor based on status
        if (book.status == 1) {
          bgColor = "#FBD38D";
        } else if (book.status == 2) {
          bgColor = "#0dcaf0";
        } else if (book.status == 3) {
          bgColor = "#FBD38D";
        } else if (book.status == 4) {
          bgColor = "#FBD38D";
        } else if (book.status == 6) {
          bgColor = "#FBD38D";
        } else if (book.status == 7) {
          bgColor = "#f5365c";
        } else if (book.status == 8) {
          bgColor = "#20c997";
        }

        this.calendarOptions.events.push({
          id: book.id,
          title: "L-" + book.id + " ( " + book.from_time + " )",
          date: book.service_date,
          backgroundColor: bgColor,
        });
      });
      //this.bookList = tmpBookList;

      this.totalBooking = this.calendarOptions.events.length;
      console.log(this.calendarOptions.events);

      this.diplayCalendar = true;
      this.contentLoaded = true;

      /*if (document.getElementById("fcalendar")) {
        var myCalendar = document.querySelector("#fcalendar");
        myCalendar.fullCalendar("render");
      }*/

      // this.calendarOptions.events = tmpBookList;
    },
  },
};
</script>
